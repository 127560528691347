<template>
    <div class="card p-2" style="min-height: 200px">
        <h4>PO Transactions</h4>
        <div class="table-responsive">
            <table class="table">
                <thead class="table-light">
                <tr>
                    <th>Date</th>
                    <th>Txn Type</th>
                    <th>Voucher No</th>
                    <th>Line Description</th>
                    <th>Account</th>
                    <th>Amount</th>
                    <th>Adjustment</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(item, i) in list" :key="i">
                    <td>{{ item.date }}</td>
                    <td>{{ item.txn_type }}</td>
                    <td>{{ item.voucher_no }}</td>
                    <td>{{ item.line_description }}</td>
                    <td>{{ item.account }}</td>
                    <td>{{ commaFormat(item.amount) }}</td>
                    <td>
                        <template v-if="is_edit">
                            <select @change="$emit('selectGrnNumber', item.id, item.amount, item.account_head_id, $event)" name="" id=""
                                    class="form-control">
                                <option value="">Select GRN</option>
                                <option v-for="(grn_number, index) in grn_numbers" :selected="selectedOptions(grn_number.ledger_generals, item.id)" :value="grn_number.id">
                                    {{ grn_number.grn_number }}
                                </option>
                            </select>
                        </template>
                        <template v-else>
                            <template v-if="item.grn_masters.length > 0">
                                <span v-for="grn in item.grn_masters" class="badge bg-primary">{{ grn.grn_number }}</span>
                            </template>
                            <select v-else @change="$emit('selectGrnNumber', item.id, item.amount, item.account_head_id, $event)" name="" id=""
                                    class="form-control">
                                <option value="">Select GRN</option>
                                <option v-for="(grn_number, index) in grn_numbers" :selected="index==0" :value="grn_number.id">
                                    {{ grn_number.grn_number }}
                                </option>
                            </select>
                        </template>
                    </td>
                </tr>
                <tr>
                    <td colspan="5">Total</td>
                    <td>{{ commaFormat(totalLGAmount) }}</td>
                    <td></td>
                </tr>
                </tbody>
            </table>
            <p v-if="!list.length" class="text-center mt-5">No data</p>
        </div>
    </div>
</template>

<script setup>

import figureFormatter from "@/services/utils/figureFormatter";
import {computed} from "vue";

const {commaFormat} = figureFormatter()
const props = defineProps({
    is_edit: {
        default: false
    },
    list: Object,
    grn_numbers: Array
});

const totalLGAmount = computed(() => {
    return props.list.reduce((sum, {amount}) => sum + Number(amount), 0)
})

const selectedOptions = (ledger_generals, id) => {
    return ledger_generals?.some(lg => lg.id === id)
}
</script>
