<template>
    <div style="min-height: calc(100vh - 158px);" class="card">
        <div class="card p-2" style="min-height: 200px">
            <div class="row mb-2">
                <div class="col-md-3">
                    <h4>Landed Costing for : {{ grn_master_list.grn_number }}</h4>
                    <h5>Warehouse: {{ grn_master_list.business_location?.name }}</h5>
                    <h5>Party: {{grn_master_list.contact_profile ? grn_master_list.contact_profile.full_name : ""}}
                    </h5>
                </div>
                <div class="col-md-3">
                    <label for="colFormLabel" class="col-form-label text-end">Account Head</label>
                    <v-select
                        placeholder="Account Head"
                        v-model="grn_master_list.account_payable_head_id"
                        :options="account_payable"
                        label="name"
                        :reduce="name => name.id"
                    />
                </div>
                <div class="col-md-3">
                    <label for="colFormLabel" class="col-form-label text-end">Voucher No</label>
                    <template v-if="grn_master_list.bill_number">
                        <div class="input-group-text">
                            <span>{{ grn_master_list.bill_number }}</span>
                        </div>
                    </template>
                    <template v-else>
                        <input type="hidden" class="form-control invoice-edit-input date-picker flatpickr-input"
                               readonly="readonly">
                        <div class="input-group input-group-merge invoice-edit-input-group">
                            <div class="input-group-text">
                                <span>{{ grn_master_list.prefix }}-</span>
                            </div>
                            <input type="text" class="form-control invoice-edit-input" placeholder=""
                                   v-model="grn_master_list.serial">
                        </div>
                    </template>
                </div>
                <div class="col-md-3">
                    <label for="colFormLabel" class="col-form-label">Date </label>
                    <input
                            class="form-control invoice-edit-input date-picker flatpickr-input flatpickr-mobile"
                            tabindex="1"
                            type="date"
                            placeholder=""
                            v-model="grn_master_list.date"
                    >
                </div>
            </div>
            <div class="table-responsive">
                <table class="table table-bordered">
                    <thead>
                    <tr>
                        <th>Item</th>
                        <th>UoM</th>
                        <th>Qty</th>
                        <th>Rate</th>
                        <th>Currency</th>
                        <th>Ex rate</th>
                        <th>Amount / unit</th>
                        <th>Weight</th>
                        <th>OH Cost</th>
                        <th>Total Cost</th>
                        <th>Per unit cost</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="(item, i) in grn_master_list.grn_general" :key="i">
                        <td> {{ item?.product?.name }}</td>
                        <td> {{ item?.product?.description?.unit?.name }}</td>
                        <td> {{ item.quantity }}</td>
                        <td>{{ item?.purchase_order_general?.rate }}</td>
                        <td>{{ item?.purchase_order_general?.currency }}</td>
                        <td><input v-model="item.ex_rate" type="number" class="form-control-sm"></td>
                        <td>
                            {{ commaFormat(totalAmount(item)) }}
                        </td>
                        <td>{{ commaFormat(totalAmount(item) / getTotalAmountUnit) }}
                        </td>
                        <td>{{ commaFormat(totalLGAmount * totalAmount(item) / getTotalAmountUnit) }}
                        </td>
                        <td>{{
                            commaFormat(totalAmount(item) + (totalLGAmount * (totalAmount(item) / getTotalAmountUnit)))
                            }}
                        </td>
                        <td>
                            {{
                            commaFormat((totalAmount(item) + (totalLGAmount * (totalAmount(item) / getTotalAmountUnit))) / item.quantity)
                            }}
                        </td>
                    </tr>
                    <tr>
                        <td colspan="6"> Total</td>
                        <td>{{ commaFormat(getTotalAmountUnit) }}</td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                    </tr>
                    </tbody>
                </table>
            </div>
            <div v-if="index==0" class="mt-2 text-center">
                <button :disabled="saveNewLoader" @click="save()" class="btn btn-primary me-1">
                    <div v-if="saveNewLoader" class="spinner-border spinner-border-sm text-light" role="status">
                        <span class="visually-hidden">Loading...</span>
                    </div>
                   {{ is_edit ? "Update" : "Save" }}
                </button>
            </div>
        </div>
    </div>
</template>

<script setup>
import {useRoute, useRouter} from "vue-router";
import {computed,ref} from "vue";
import figureFormatter from '@/services/utils/figureFormatter'

const {commaFormat} = figureFormatter()
const props = defineProps({
    grn_master_list: Object,
    account_payable: Array,
    index: Number,
    is_edit: {
        default: false
    },
});
const emits = defineEmits(["storeLandedCostingData"])

const saveNewLoader = ref(false)

const save = () => {
    emits('storeLandedCostingData', props.grn_master_list, totalLGAmount.value)
}

const totalLGAmount = computed(() => {
    return props.grn_master_list.amounts.reduce((sum, {amount}) => sum + Number(amount), 0)
})

const totalAmount = (item) => {
    return item.quantity * item?.purchase_order_general?.rate * item.ex_rate
}

const route = useRoute();
const router = useRouter();

const hasNextNonEmptyName = (index) => {
  let nextIndex = index + 1;
  while (nextIndex < props.grn_master_list.ledger_generals.length) {
    if (props.grn_master_list.ledger_generals[nextIndex].party?.full_name) {
      return true;
    }
    nextIndex++;
  }
  return false;
}

const getTotalAmountUnit = computed(() => {
    let total = 0;
    props.grn_master_list.grn_general.forEach((item) => {
        total += item.quantity * item?.purchase_order_general?.rate * item.ex_rate
    })
    return total;
});

</script>
<style scoped>
.form-control-sm {
    width: 70px;
}
</style>
