import Network from '@/services/network'
import {ref} from 'vue'

export default function handleLandedCosting() {
    const network = new Network;
    const loading = ref(false)

    const fetchLGLandedCosting = (query) => {
        return network.api('get', '/landed-costing/lg' + query);
    }
    const fetchGrnLandedCosting = (query) => {
        return network.api('get', '/landed-costing/grn' + query);
    }
    const fetchLandedCosting = (query) => {
        return network.api('get', '/landed-costing' + query);
    }
    const storeLandedCosting = (data, query) => {
        return network.api('post', '/landed-costing' + query, data);
    }
    const fetchSingleLandedCosting = (lCId, query) => {
        return network.api('get', `/landed-costing/${lCId}` + query);
    }
    const updateLandedCosting = (id, data, query) => {
        return network.api('put', `/landed-costing/${id}` + query, data);
    }


    return {
        fetchLGLandedCosting,
        fetchGrnLandedCosting,
        fetchLandedCosting,
        storeLandedCosting,
        fetchSingleLandedCosting,
        updateLandedCosting,
        loading
    }
}
